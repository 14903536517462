<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            {{ $t("editDeliveryModal.editDelivery") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`confirmModal.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div>
            <label class="form-label bold-12 text-gray">
              {{ $t("editDeliveryModal.deliveryPrice") }}
            </label>
            <input
              type="number"
              class="form-control"
              min="0"
              v-model="value.payed_price"
              :max="max"
            />
          </div>
          <div>
            <label class="form-label bold-12 text-gray">
              {{ $t("editDeliveryModal.paymentFeePrice") }}
            </label>
            <input
              type="number"
              class="form-control"
              min="0"
              v-model="value.payed_fee"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" v-on:click="save">
            {{ $t("finance.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const bootstrap = require("bootstrap");

export default {
  name: "EditDeliveryModal",
  props: {
    modelValue: Object,
  },
  data() {
    return {
      value: this.modelValue,
      max: null,
    };
  },
  watch: {
    modelValue() {
      this.value = this.modelValue;
      this.max = this.modelValue.payed_price;
    },
  },
  methods: {
    showModal() {
      if (this.myModal === undefined) {
        this.myModal = new bootstrap.Modal(this.$refs.modal, {
          backdrop: "static",
        });
      }
      this.myModal.show();
    },
    save() {
      this.$emit("change", this.value);
      this.myModal.hide();
    },
  },
  emits: ["update:modelValue", "change"],
};
</script>
