<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            {{ $t("receiptModal.receipt") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`orderChange.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div>
            <div class="row" v-if="modelValue && modelValue.content">
              <div
                class="col-12 mb-3 pb-3 border-bottom"
                v-html="modelValue.content.replaceAll('\n', '<br>')"
              ></div>
              <div class="col-4">{{ $t("receiptModal.number3") }}</div>
              <div class="col-8">{{ modelValue.number }}</div>
              <div class="col-4">{{ $t("receiptModal.serial2") }}</div>
              <div class="col-8">{{ modelValue.serial }}</div>
              <div class="col-4">{{ $t("receiptModal.taxId2") }}</div>
              <div class="col-8">{{ modelValue.tax_number }}</div>
              <div class="col-4">{{ $t("receiptModal.cashRegister2") }}</div>
              <div class="col-8">{{ modelValue.cash_register_id }}</div>
              <div class="col-4">{{ $t("pos.payed") }}</div>
              <div class="col-8">
                {{ modelValue.payed_amount }} {{ modelValue.currency_code }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const bootstrap = require("bootstrap");

export default {
  name: "ReceiptModal",
  props: {
    modelValue: Object,
  },
  data() {
    return {};
  },
  methods: {
    showModal() {
      if (this.myModal === undefined) {
        this.myModal = new bootstrap.Modal(this.$refs.modal, {
          backdrop: "static",
        });
      }
      this.myModal.show();
    },
  },
};
</script>
