<template>
  <span class="regular-12 text-black text-start">
   <BaseIcon :name="value?.code?.toLowerCase()" v-if="value?.code" type="flag" class="flag" />
  <span class="px-2">{{value?.name}}</span>
 </span>
</template>

<script>
import { defineAsyncComponent } from "vue";
const BaseIcon = defineAsyncComponent(() => import("../../icons/BaseIcon.vue"));

export default {
  name: "Country",
  components: { BaseIcon },
  props: {
    id: Number,
    type: String,
    row: Object,
    modelValue: Object,
  },
  data() {
    return {
      value: this.modelValue,
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("change");
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  emits: ["update:modelValue", "change"],
};
</script>
